export default {
  appDescription_admin: 'Manage & Invite Users',
  appDescription_insights: 'Enrollment Analytics',
  appDescription_print: 'Build & Manage Print Material',
  appDescription_sms: 'Send & Receive SMS Messages',
  appDescription_sysadmin: 'System Level Org Management',
  appName_admin: 'USER MANAGEMENT',
  appName_insights: 'INSIGHTS',
  appName_print: 'PRINT',
  appName_sms: 'SMS',
  appName_sysadmin: 'ORG MANAGEMENT',
  signOutLinkLabel: 'Sign Out',
  title: 'TargetX Home'
};
