export namespace NavigateCommand {
  export interface Properties {
    path: string;
  }
}

export class NavigateCommand {
  public readonly path: string;

  public constructor({ path }: NavigateCommand.Properties) {
    this.path = path;
  }
}

export default NavigateCommand;
