export default {
  confirmPasswordInputLabel: 'Confirm New Password',
  oldPasswordInputLabel: 'Old Password',
  passwordInputError: `Passwords don't match`,
  passwordInputCaption:
    'Use at least 8 characters, with one lowercase letter, one capital letter, a number, and a special character.',
  passwordInputLabel: 'New Password',
  resetButtonLabel: 'RESET',
  submitButtonLabel: 'SAVE',
  title: 'Change Password'
};
