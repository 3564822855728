/* eslint-disable @typescript-eslint/no-explicit-any */

import { navigate } from '@reach/router';
import { ThemeProvider } from '@targetx/mineral-ui/themes';
import HTTPMessageSender from '@targetx/tx-cq-lib/lib/HTTPMessageSender';
import ClearUserSalesforceMappingCommand, {
  ClearUserSalesforceMappingCommandAck,
  ClearUserSalesforceMappingCommandFailure
} from '@targetx/tx-usermgmt-api-lib/lib/commands/ClearUserSalesforceMappingCommand';
import UpdatePasswordCommand, {
  UpdatePasswordCommandAck,
  UpdatePasswordCommandFailure,
  UpdatePasswordCommandWithOldPassword
} from '@targetx/tx-usermgmt-api-lib/lib/commands/UpdatePasswordCommand';
import UpdateUserCommand, {
  UpdateUserCommandAck,
  UpdateUserCommandFailure
} from '@targetx/tx-usermgmt-api-lib/lib/commands/UpdateUserCommand';
import UserQuery, {
  UserQueryFailure,
  UserQueryResult
} from '@targetx/tx-usermgmt-api-lib/lib/queries/UserQuery';
import React, { ReactElement } from 'react';
import UDispatcher from 'unilib-dispatcher';
import registry from 'unilib-registry/instance';
import App from './App';
import NavigateCommand from './commands/NavigateCommand';
import RedirectCommand from './commands/RedirectCommand';
import config from './config';
import DispatcherContext, { Dispatcher } from './DispatcherContext';
import theme from './theme';
import redirectOnAuthError from './utils/redirectOnAuthError';

//
// Message Senders
//

registry.register(
  'UserManagementService',
  (): HTTPMessageSender =>
    new HTTPMessageSender({
      constructorRegistry: {
        [ClearUserSalesforceMappingCommandAck.name]: ClearUserSalesforceMappingCommandAck,
        [ClearUserSalesforceMappingCommandFailure.name]: ClearUserSalesforceMappingCommandFailure,
        [UpdatePasswordCommandAck.name]: UpdatePasswordCommandAck,
        [UpdatePasswordCommandFailure.name]: UpdatePasswordCommandFailure,
        [UpdateUserCommandAck.name]: UpdateUserCommandAck,
        [UpdateUserCommandFailure.name]: UpdateUserCommandFailure,
        [UserQueryFailure.name]: UserQueryFailure,
        [UserQueryResult.name]: UserQueryResult
      },
      endpointURL: config.USERMGMT_API_BASE_URL
    })
);

//
// Dispatcher
//

registry.register(
  'dispatcher',
  (): UDispatcher => {
    const dispatcher = new UDispatcher();
    const userManagementService = registry.get('UserManagementService');

    //
    // Queries
    //

    dispatcher.register(
      UserQuery.name,
      async (query: UserQuery): Promise<UserQueryResult | UserQueryFailure> => {
        try {
          return await userManagementService.send(query);
        } catch (error) {
          console.error(error);
          await redirectOnAuthError(error, config.AUTH_BASE_URL);
          return new UserQueryFailure({
            correlationUUID: query.uuid,
            reason: UserQueryFailure.REASON_UNEXPECTED
          });
        }
      }
    );

    //
    // Commands
    //

    dispatcher.register(
      ClearUserSalesforceMappingCommand.name,
      async (
        command: ClearUserSalesforceMappingCommand
      ): Promise<
        | ClearUserSalesforceMappingCommandAck
        | ClearUserSalesforceMappingCommandFailure
      > => {
        try {
          return await userManagementService.send(command);
        } catch (error) {
          console.error(error);
          await redirectOnAuthError(error, config.AUTH_BASE_URL);
          return new ClearUserSalesforceMappingCommandFailure({
            correlationUUID: command.uuid,
            reason: ClearUserSalesforceMappingCommandFailure.REASON_UNEXPECTED
          });
        }
      }
    );

    dispatcher.register(
      NavigateCommand.name,
      (command: NavigateCommand): Promise<void> => navigate(command.path)
    );

    dispatcher.register(
      RedirectCommand.name,
      (command: RedirectCommand): void => window.location.replace(command.url)
    );

    dispatcher.register(
      UpdatePasswordCommandWithOldPassword.name,
      async (
        command: UpdatePasswordCommand
      ): Promise<UpdatePasswordCommandAck | UpdatePasswordCommandFailure> => {
        try {
          return await userManagementService.send(command);
        } catch (error) {
          console.error(error);
          await redirectOnAuthError(error, config.AUTH_BASE_URL);
          return new UpdatePasswordCommandFailure({
            correlationUUID: command.uuid,
            reason: UpdatePasswordCommandFailure.REASON_UNEXPECTED
          });
        }
      }
    );

    dispatcher.register(
      UpdateUserCommand.name,
      async (
        command: UpdateUserCommand
      ): Promise<UpdateUserCommandAck | UpdateUserCommandFailure> => {
        try {
          return await userManagementService.send(command);
        } catch (error) {
          console.error(error);
          await redirectOnAuthError(error, config.AUTH_BASE_URL);
          return new UpdateUserCommandFailure({
            correlationUUID: command.uuid,
            reason: UpdateUserCommandFailure.REASON_UNEXPECTED
          });
        }
      }
    );

    return dispatcher;
  }
);

registry.set(
  'Root',
  (): ReactElement =>
    React.createElement(
      ThemeProvider,
      { theme },
      React.createElement(
        DispatcherContext.Provider,
        {
          value: {
            async dispatch(payload: Dispatcher.Payload): Promise<any> {
              try {
                const dispatcher: UDispatcher = registry.get('dispatcher');
                return await dispatcher.dispatch(
                  payload.constructor.name,
                  payload
                );
              } catch (error) {
                console.error(error);
              }
            }
          }
        },
        React.createElement(App)
      )
    )
);
