import Box from '@targetx/mineral-ui/Box';
import Button from '@targetx/mineral-ui/Button';
import Flex from '@targetx/mineral-ui/Flex';
import { FormField } from '@targetx/mineral-ui/Form';
import Text from '@targetx/mineral-ui/Text';
import TextInput from '@targetx/mineral-ui/TextInput';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import Asterisk from '@targetx/tx-web-ui-lib/lib/components/Asterisk';
import Form from '@targetx/tx-web-ui-lib/lib/components/Form';
import noop from 'lodash.noop';
import React, {
  ChangeEvent,
  FormEvent,
  MouseEvent,
  ReactElement,
  useState
} from 'react';
import isEmpty from 'validator/lib/isEmpty';
import theme from '../theme';
import { InteractionDelegate } from '../types/Interaction';
import { PartialState } from '../types/PartialState';
import copyText from './UpdatePasswordForm.copyText';

export namespace UpdatePasswordForm {
  export interface Props {
    isProcessing?: boolean;
    onInteraction?: InteractionDelegate;
  }
}

interface State {
  oldPasswordInput: { value: string; isValid: boolean };
  passwordInput: { value: string; isValid: boolean };
  confirmPasswordInput: { value: string; isValid: boolean };
}

export function UpdatePasswordForm({
  isProcessing,
  onInteraction = noop
}: UpdatePasswordForm.Props): ReactElement {
  const initialState = {
    oldPasswordInput: { value: '', isValid: true },
    passwordInput: { value: '', isValid: false },
    confirmPasswordInput: { value: '', isValid: false }
  };

  const [state, setState] = useState<State>(initialState);

  function changeState(partialState: PartialState<State>): void {
    setState(currentState => ({ ...currentState, ...partialState }));
  }

  const { oldPasswordInput, passwordInput, confirmPasswordInput } = state;

  const canSubmit = Object.values(state).every(
    (input): boolean => !isEmpty(input.value) && input.isValid
  );

  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    const { name, value } = event.target;

    let isValid = false;

    switch (name) {
      case 'oldPassword':
        isValid = value.length >= 8;
        break;
      case 'password':
        isValid = value.length >= 8;
        break;
      case 'confirmPassword':
        isValid = value === passwordInput.value;
        break;
      default:
        break;
    }

    changeState({
      [`${name}Input`]: { value, isValid },
      ...(name === 'password'
        ? {
            confirmPasswordInput: {
              value: '',
              isValid: false
            }
          }
        : {})
    });
  }

  function handleReset(event: MouseEvent<HTMLButtonElement>): void {
    event.preventDefault();

    changeState(initialState);
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();

    onInteraction({
      type: UpdatePasswordForm.INTERACTION_SUBMIT_BUTTON_CLICKED,
      oldPassword: oldPasswordInput.value,
      password: passwordInput.value
    });

    changeState(initialState);
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Box
        backgroundColor={palette.white}
        border={`1px solid ${palette.gray[50]}`}
        borderRadius={theme.borderRadius_2}
        padding={theme.space_inset_lg}
      >
        <FormField
          name="oldPassword"
          input={TextInput}
          label={
            <Text
              color={!isValidInput(oldPasswordInput) ? theme.color_danger : ''}
            >
              {copyText.oldPasswordInputLabel}
              <Asterisk color={theme.color_danger} />
            </Text>
          }
          type="password"
          value={oldPasswordInput.value}
          variant={!isValidInput(oldPasswordInput) ? 'danger' : undefined}
          onChange={handleChange}
        />
        <FormField
          name="password"
          input={TextInput}
          label={
            <Text
              color={!isValidInput(passwordInput) ? theme.color_danger : ''}
            >
              {copyText.passwordInputLabel}
              <Asterisk color={theme.color_danger} />
            </Text>
          }
          marginTop={theme.space_stack_md}
          type="password"
          value={passwordInput.value}
          variant={!isValidInput(passwordInput) ? 'danger' : undefined}
          onChange={handleChange}
        />
        <FormField
          name="confirmPassword"
          caption={
            <Box marginTop={theme.space_stack_xs}>
              {!isValidInput(confirmPasswordInput)
                ? renderPasswordInputError()
                : null}
              <Text color={palette.gray[80]} fontSize={theme.fontSize_ui_sm}>
                {copyText.passwordInputCaption}
              </Text>
            </Box>
          }
          input={TextInput}
          label={
            <Text
              color={
                !isValidInput(confirmPasswordInput) ? theme.color_danger : ''
              }
            >
              {copyText.confirmPasswordInputLabel}
              <Asterisk color={theme.color_danger} />
            </Text>
          }
          marginTop={theme.space_stack_md}
          type="password"
          value={confirmPasswordInput.value}
          variant={!isValidInput(confirmPasswordInput) ? 'danger' : undefined}
          onChange={handleChange}
        />
      </Box>
      <Flex justifyContent="end" paddingVertical={theme.space_stack_lg}>
        <Button
          marginRight={20}
          minimal
          size="medium"
          type="reset"
          width="100px"
          onClick={handleReset}
        >
          {copyText.resetButtonLabel}
        </Button>
        <Button
          disabled={!canSubmit || isProcessing}
          primary
          size="medium"
          type="submit"
          width="100px"
        >
          {copyText.submitButtonLabel}
        </Button>
      </Flex>
    </Form>
  );
}

function isValidInput({
  value,
  isValid
}: {
  value: string;
  isValid: boolean;
}): boolean {
  return isEmpty(value) || isValid;
}

function renderPasswordInputError(): ReactElement | null {
  return (
    <Text
      color={theme.color_danger}
      fontSize={theme.fontSize_ui_sm}
      marginBottom={theme.space_stack_sm}
    >
      {copyText.passwordInputError}
    </Text>
  );
}

UpdatePasswordForm.INTERACTION_SUBMIT_BUTTON_CLICKED = `${UpdatePasswordForm.name}.INTERACTION_SUBMIT_BUTTON_CLICKED`;

export default UpdatePasswordForm;
