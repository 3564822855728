import { Router } from '@reach/router';
import {
  APP_SID_ADMIN,
  APP_SID_INSIGHTS,
  APP_SID_PRINT,
  APP_SID_SMS
} from '@targetx/tx-usermgmt-api-lib/lib/constants/apps';
import UserQuery, {
  UserQueryResult
} from '@targetx/tx-usermgmt-api-lib/lib/queries/UserQuery';
import ErrorScreen from '@targetx/tx-web-ui-lib/lib/screens/ErrorScreen';
import LoadingScreen from '@targetx/tx-web-ui-lib/lib/screens/LoadingScreen';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import copyText from './App.copyText';
import config from './config';
import paths from './constants/paths';
import DispatcherContext from './DispatcherContext';
import HomeScreen from './screens/HomeScreen';
import ProfileScreen from './screens/ProfileScreen';
import { PartialState } from './types/PartialState';
import trackUser from './utils/trackUser';

const appURLRegistry = {
  [APP_SID_ADMIN]: config.ADMIN_BASE_URL,
  [APP_SID_INSIGHTS]: config.INSIGHTS_BASE_URL,
  [APP_SID_PRINT]: config.PRINT_BASE_URL,
  [APP_SID_SMS]: config.SMS_BASE_URL
};

const LOADING_SCREEN_TIMEOUT = 2000;

interface State {
  authenticatedUser?: UserQueryResult.UserEntity;
  isAuthenticating: boolean;
  showLoadingScreen: boolean;
}

export function App(): ReactElement {
  const initialState = {
    isAuthenticating: false,
    showLoadingScreen: true
  };

  const [state, setState] = useState<State>(initialState);

  function changeState(partialState: PartialState<State>): void {
    setState(prevState => ({ ...prevState, ...partialState }));
  }

  const { isAuthenticating, showLoadingScreen } = state;

  //
  // Dispatchers
  //

  const dispatcher = useContext(DispatcherContext);

  async function dispatchUserQuery(): Promise<void> {
    changeState({ isAuthenticating: true });

    const result = await dispatcher.dispatch(new UserQuery());

    if (result instanceof UserQueryResult) {
      trackUser(result.user.id, {
        'First Name': result.user.firstName,
        'Last Name': result.user.lastName,
        Username: result.user.username,
        Role: result.user.role,
        'Time Last Authenticated': result.user.timeLastAuthenticated,
        'Org ID': result.user.org.id,
        'Org Name': result.user.org.name,
        'Org SID': result.user.org.sid,
        ...(result.user.salesforceProfile
          ? {
              'Salesforce User ID': result.user.salesforceProfile.user_id,
              'Salesforce Org ID': result.user.salesforceProfile.organization_id
            }
          : {})
      });

      changeState({ isAuthenticating: false, authenticatedUser: result.user });
      return;
    }

    changeState({ isAuthenticating: false });
  }

  async function initialize(): Promise<void> {
    dispatchUserQuery();

    const timer = setTimeout((): void => {
      clearTimeout(timer);
      changeState({ showLoadingScreen: false });
    }, LOADING_SCREEN_TIMEOUT);
  }

  //
  // Side Effects
  //

  useEffect((): void => {
    initialize();
  }, []);

  //
  // Render
  //

  if (showLoadingScreen || isAuthenticating) {
    return <LoadingScreen />;
  }

  const authenticatedUser = state.authenticatedUser as UserQueryResult.UserEntity;

  const salesforceAuthConnectPath = `${config.AUTH_BASE_URL}/auth/salesforce/connect`;
  const signOutPath = `${config.AUTH_BASE_URL}/${authenticatedUser.org.sid}/sign-in`;

  return (
    <Router>
      <HomeScreen
        appURLRegistry={appURLRegistry}
        authenticatedUser={authenticatedUser}
        path="/"
        signOutPath={signOutPath}
      />
      <ProfileScreen
        authenticatedUser={authenticatedUser}
        salesforceAuthConnectPath={salesforceAuthConnectPath}
        path={paths.profile}
        signOutPath={signOutPath}
      />
      <ErrorScreen
        default
        heading={copyText.ERROR_PERMISSION_DENIED_title}
        message={copyText.ERROR_PERMISSION_DENIED_message}
        returnButtonLabel={copyText.returnButtonLabel}
        returnButtonPath="/"
      />
    </Router>
  );
}

export default App;
