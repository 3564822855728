function throwError(key: string): never {
  throw new Error(`process.env.${key} is required`);
}

// prettier-ignore

export default {
  ADMIN_BASE_URL: process.env.TX_ADMIN_BASE_URL ?? throwError('TX_ADMIN_BASE_URL'),
  AUTH_BASE_URL: process.env.TX_AUTH_BASE_URL ?? throwError('TX_AUTH_BASE_URL'),
  INSIGHTS_BASE_URL: process.env.TX_INSIGHTS_BASE_URL ?? throwError('TX_INSIGHTS_BASE_URL'),
  PRINT_BASE_URL: process.env.TX_PRINT_BASE_URL ?? throwError('TX_PRINT_BASE_URL'),
  SMS_BASE_URL: process.env.TX_SMS_BASE_URL ?? throwError('TX_SMS_BASE_URL'),
  USERMGMT_API_BASE_URL: process.env.TX_USERMGMT_API_BASE_URL ?? throwError('TX_USERMGMT_API_BASE_URL')
};
