/* eslint-disable @typescript-eslint/no-explicit-any */

import Box from '@targetx/mineral-ui/Box';
import Button from '@targetx/mineral-ui/Button';
import Flex, { FlexItem } from '@targetx/mineral-ui/Flex';
import Text from '@targetx/mineral-ui/Text';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import MoreInfoPopover from '@targetx/tx-web-ui-lib/lib/components/MoreInfoPopover';
import IconCheckCircle from '@targetx/tx-web-ui-lib/lib/icons/IconCheckCircle';
import SVGSpinner from '@targetx/tx-web-ui-lib/lib/svg/SVGSpinner';
import get from 'lodash.get';
import noop from 'lodash.noop';
import React, { ReactElement } from 'react';
import theme from '../theme';
import { InteractionDelegate } from '../types/Interaction';
import copyText from './SalesforceIntegrationComponent.copyText';

const MAX_POPOVER_WIDTH = 300;

export namespace SalesforceIntegrationComponent {
  export interface UserEntity {
    id: string;
    salesforceID?: string;
    salesforceProfile?: { [key: string]: any };
  }

  export interface Props {
    user: UserEntity;
    isProcessing?: boolean;
    onInteraction?: InteractionDelegate;
  }
}

export function SalesforceIntegrationComponent({
  user,
  isProcessing,
  onInteraction = noop
}: SalesforceIntegrationComponent.Props): ReactElement {
  function handleClickConnectButton(): void {
    onInteraction({
      type: SalesforceIntegrationComponent.INTERACTION_CONNECT_BUTTON_CLICKED
    });
  }

  function handleClickDisconnectButton(): void {
    onInteraction({
      type: SalesforceIntegrationComponent.INTERACTION_DISCONNECT_BUTTON_CLICKED
    });
  }
  return (
    <Box>
      <Box
        backgroundColor={palette.white}
        border={`1px solid ${palette.gray[50]}`}
        borderRadius={theme.borderRadius_2}
        padding={theme.space_inset_lg}
      >
        <Flex justifyContent="between">
          <FlexItem>
            <Flex>
              <Text
                fontWeight="semiBold"
                marginBottom={theme.space_stack_xs}
                marginRight={theme.space_inline_sm}
              >
                {copyText.heading}
              </Text>
              <MoreInfoPopover
                color={palette.blue[60]}
                hasMarkdown
                maxWidth={MAX_POPOVER_WIDTH}
                padding={theme.space_inset_md}
                placement="right-start"
              >
                {copyText.moreInfoDescription}
              </MoreInfoPopover>
            </Flex>
            <Text>
              {user.salesforceID
                ? copyText.connectedDescription
                : copyText.description}
            </Text>
          </FlexItem>
          <FlexItem flex alignItems="center">
            {user.salesforceID ? (
              <IconCheckCircle color={palette.green[60]} size={40} solid />
            ) : (
              <IconCheckCircle color={palette.gray[30]} size={40} solid />
            )}
          </FlexItem>
        </Flex>
        {user.salesforceID ? (
          <Box
            marginTop={theme.space_stack_md}
            paddingTop={theme.space_stack_md}
            borderTop={`1px solid ${palette.gray[30]}`}
          >
            <Text fontWeight="semiBold" marginBottom={theme.space_stack_xs}>
              {copyText.usernameHeading}
            </Text>
            <Text>
              {get(
                user,
                'salesforceProfile.preferred_username',
                copyText.notAvailable
              )}
            </Text>
          </Box>
        ) : null}
      </Box>
      <Flex justifyContent="end" paddingVertical={theme.space_stack_lg}>
        {user.salesforceID ? (
          <Button
            disabled={isProcessing}
            primary
            size="medium"
            variant="danger"
            width="125px"
            onClick={handleClickDisconnectButton}
          >
            {isProcessing ? (
              <SVGSpinner fill={palette.white} size="2em" />
            ) : (
              copyText.disconnectButtonLabel
            )}
          </Button>
        ) : (
          <Button
            primary
            size="medium"
            width="125px"
            onClick={handleClickConnectButton}
          >
            {copyText.connectButtonLabel}
          </Button>
        )}
      </Flex>
    </Box>
  );
}

SalesforceIntegrationComponent.INTERACTION_CONNECT_BUTTON_CLICKED = `${SalesforceIntegrationComponent.name}.INTERACTION_CONNECT_BUTTON_CLICKED`;
SalesforceIntegrationComponent.INTERACTION_DISCONNECT_BUTTON_CLICKED = `${SalesforceIntegrationComponent.name}.INTERACTION_DISCONNECT_BUTTON_CLICKED`;

export default SalesforceIntegrationComponent;
