import Avatar from '@targetx/mineral-ui/Avatar';
import Box from '@targetx/mineral-ui/Box';
import Flex, { FlexItem } from '@targetx/mineral-ui/Flex';
import Text from '@targetx/mineral-ui/Text';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import ClearUserSalesforceMappingCommand, {
  ClearUserSalesforceMappingCommandAck,
  ClearUserSalesforceMappingCommandFailure
} from '@targetx/tx-usermgmt-api-lib/lib/commands/ClearUserSalesforceMappingCommand';
import {
  UpdatePasswordCommandAck,
  UpdatePasswordCommandFailure,
  UpdatePasswordCommandWithOldPassword
} from '@targetx/tx-usermgmt-api-lib/lib/commands/UpdatePasswordCommand';
import UpdateUserCommand, {
  UpdateUserCommandAck,
  UpdateUserCommandFailure
} from '@targetx/tx-usermgmt-api-lib/lib/commands/UpdateUserCommand';
import UserQuery, {
  UserQueryFailure,
  UserQueryResult
} from '@targetx/tx-usermgmt-api-lib/lib/queries/UserQuery';
import Alert from '@targetx/tx-web-ui-lib/lib/components/Alert';
import Breadcrumb from '@targetx/tx-web-ui-lib/lib/components/Breadcrumb';
import Layout from '@targetx/tx-web-ui-lib/lib/components/Layout';
import SideNav from '@targetx/tx-web-ui-lib/lib/components/SideNav';
import IconKey from '@targetx/tx-web-ui-lib/lib/icons/IconKey';
import IconSalesforce from '@targetx/tx-web-ui-lib/lib/icons/IconSalesforce';
import IconTable from '@targetx/tx-web-ui-lib/lib/icons/IconTable';
import get from 'lodash.get';
import React, { ReactElement, ReactNode, useContext, useState } from 'react';
import RedirectCommand from '../commands/RedirectCommand';
import EditProfileBasicInfoForm from '../components/EditProfileBasicInfoForm';
import SalesforceIntegrationComponent from '../components/SalesforceIntegrationComponent';
import UpdatePasswordForm from '../components/UpdatePasswordForm';
import DispatcherContext from '../DispatcherContext';
import theme from '../theme';
import { UserEntity } from '../types';
import Interaction from '../types/Interaction';
import { PartialState } from '../types/PartialState';
import copyText from './ProfileScreen.copyText';

const FAILURE_CLEARING_USER_SALESFORCE_MAPPING =
  'FAILURE_CLEARING_USER_SALESFORCE_MAPPING';
const FAILURE_LOADING_USER = 'FAILURE_LOADING_USER';
const FAILURE_UPDATING_PASSWORD = 'FAILURE_UPDATING_PASSWORD';
('FAILURE_UPDATING_PASSWORD_UNEXPECTED');
const FAILURE_UPDATING_USER_UNEXPECTED = 'FAILURE_UPDATING_USER_UNEXPECTED';

const SUCCESS_PASSWORD_UPDATED = 'SUCCESS_PASSWORD_UPDATED';
const SUCCESS_USER_SALESFORCE_MAPPING_CLEARED =
  'SUCCESS_USER_SALESFORCE_MAPPING_CLEARED';
const SUCCESS_USER_UPDATED = 'SUCCESS_USER_UPDATED';

const TAB_INTEGRATIONS = 'INTEGRATIONS';
const TAB_PASSWORD = 'UPDATE_PASSWORD';
const TAB_PROFILE = 'EDIT_PROFILE';

const SCREEN_CONTENT_WIDTH = 450;

export namespace ProfileScreen {
  export interface Props {
    authenticatedUser: UserEntity;
    salesforceAuthConnectPath: string;
    path?: string;
    signOutPath: string;
  }
}

interface UpdatePasswordParameters {
  oldPassword: string;
  password: string;
}

interface UpdateUserParameters {
  firstName?: string;
  lastName?: string;
}

interface State {
  alertKey: string;
  currentTab: string;
  isDisconnectingUserFromSalesforce: boolean;
  isLoadingUser: boolean;
  isUpdatingPassword: boolean;
  isUpdatingUser: boolean;
  user: UserEntity;
}

export function ProfileScreen({
  authenticatedUser,
  salesforceAuthConnectPath,
  signOutPath
}: ProfileScreen.Props): ReactElement {
  const initialState: State = {
    alertKey: '',
    currentTab: TAB_PROFILE,
    isDisconnectingUserFromSalesforce: false,
    isLoadingUser: false,
    isUpdatingPassword: false,
    isUpdatingUser: false,
    user: authenticatedUser
  };

  const [state, setState] = useState<State>(initialState);

  function changeState(partialState: PartialState<State>): void {
    setState(currentState => ({ ...currentState, ...partialState }));
  }

  const {
    alertKey,
    currentTab,
    isDisconnectingUserFromSalesforce,
    isUpdatingPassword,
    isUpdatingUser,
    user
  } = state;

  //
  // Dispatchers
  //

  const dispatcher = useContext(DispatcherContext);

  async function disconnectUserFromSalesforce(userID: string): Promise<void> {
    changeState({ alertKey: '', isDisconnectingUserFromSalesforce: true });

    const ack = await dispatcher.dispatch<
      | ClearUserSalesforceMappingCommandAck
      | ClearUserSalesforceMappingCommandFailure
    >(new ClearUserSalesforceMappingCommand({ userID }));

    if (ack instanceof ClearUserSalesforceMappingCommandFailure) {
      changeState({
        alertKey: FAILURE_CLEARING_USER_SALESFORCE_MAPPING,
        isDisconnectingUserFromSalesforce: false
      });
      return;
    }

    changeState({
      alertKey: SUCCESS_USER_SALESFORCE_MAPPING_CLEARED,
      isDisconnectingUserFromSalesforce: false
    });

    getUser();
  }

  async function getUser(): Promise<void> {
    changeState({ isLoadingUser: true });

    const result = await dispatcher.dispatch<
      UserQueryResult | UserQueryFailure
    >(new UserQuery());

    if (result instanceof UserQueryFailure) {
      changeState({ alertKey: FAILURE_LOADING_USER, isLoadingUser: false });
      return;
    }

    changeState({ isLoadingUser: false, user: result.user });
  }

  function redirect(url: string): void {
    dispatcher.dispatch(new RedirectCommand({ url }));
  }

  async function updatePassword(
    userID: string,
    parameters: UpdatePasswordParameters
  ): Promise<void> {
    const { oldPassword, password } = parameters;

    changeState({ alertKey: '', isUpdatingPassword: true });

    const ack = await dispatcher.dispatch<
      UpdatePasswordCommandAck | UpdatePasswordCommandFailure
    >(
      new UpdatePasswordCommandWithOldPassword({
        userID,
        oldPassword,
        password
      })
    );

    if (ack instanceof UpdatePasswordCommandFailure) {
      const alertKey = ack.reason.replace(
        `${UpdatePasswordCommandFailure.name}/`,
        `${FAILURE_UPDATING_PASSWORD}_`
      );

      changeState({ alertKey, isUpdatingPassword: false });
      return;
    }

    changeState({
      alertKey: SUCCESS_PASSWORD_UPDATED,
      isUpdatingPassword: false
    });

    getUser();
  }

  async function updateUser(
    userID: string,
    parameters: UpdateUserParameters
  ): Promise<void> {
    const { firstName, lastName } = parameters;

    changeState({ alertKey: '', isUpdatingUser: true });

    const ack = await dispatcher.dispatch<
      UpdateUserCommandAck | UpdateUserCommandFailure
    >(new UpdateUserCommand({ userID, firstName, lastName }));

    if (ack instanceof UpdateUserCommandFailure) {
      changeState({
        alertKey: FAILURE_UPDATING_USER_UNEXPECTED,
        isUpdatingUser: false
      });
      return;
    }

    changeState({ alertKey: SUCCESS_USER_UPDATED, isUpdatingUser: false });

    getUser();
  }

  //
  // Interaction Handlers
  //

  function handleChangeTab(currentTab: string): void {
    changeState({ alertKey: '', currentTab });
  }

  function handleClickRootLayout(): void {
    if (alertKey === '') return;

    changeState({ alertKey: '' });
  }

  function handleInteraction({ type, ...data }: Interaction): void {
    switch (type) {
      case EditProfileBasicInfoForm.INTERACTION_SUBMIT_BUTTON_CLICKED: {
        updateUser(authenticatedUser.id, {
          firstName: data.firstName,
          lastName: data.lastName
        });
        return;
      }
      case SalesforceIntegrationComponent.INTERACTION_CONNECT_BUTTON_CLICKED: {
        redirect(salesforceAuthConnectPath);
        return;
      }
      case SalesforceIntegrationComponent.INTERACTION_DISCONNECT_BUTTON_CLICKED: {
        disconnectUserFromSalesforce(authenticatedUser.id);
        return;
      }
      case UpdatePasswordForm.INTERACTION_SUBMIT_BUTTON_CLICKED: {
        updatePassword(authenticatedUser.id, {
          oldPassword: data.oldPassword,
          password: data.password
        });
        return;
      }
    }
  }

  //
  // Render
  //

  function renderScreenAlert(): ReactNode {
    if (alertKey === '') return null;

    return (
      <ScreenLevelAlert
        alertKey={alertKey}
        message={get(copyText, `${alertKey}_message`)}
        showCloseButton={true}
      />
    );
  }

  function renderTab(): ReactNode {
    switch (currentTab) {
      case TAB_INTEGRATIONS: {
        return (
          <SalesforceIntegrationComponent
            isProcessing={isDisconnectingUserFromSalesforce}
            user={user}
            onInteraction={handleInteraction}
          />
        );
      }
      case TAB_PROFILE: {
        return (
          <>
            <UserProfileHeader user={user} />
            <EditProfileBasicInfoForm
              key={`${user.id}-${user.timeModified || '0'}`}
              isProcessing={isUpdatingUser}
              user={user}
              onInteraction={handleInteraction}
            />
          </>
        );
      }
      case TAB_PASSWORD: {
        return (
          <UpdatePasswordForm
            isProcessing={isUpdatingPassword}
            onInteraction={handleInteraction}
          />
        );
      }
    }
  }

  let label = '';

  switch (currentTab) {
    case TAB_INTEGRATIONS: {
      label = copyText.tabTitleIntegrations;
      break;
    }
    case TAB_PROFILE: {
      label = copyText.tabTitleEditBasicInfo;
      break;
    }
    case TAB_PASSWORD: {
      label = copyText.tabTitleUpdatePassword;
      break;
    }
  }

  const breadcrumbItems = [
    { label: copyText.title },
    {
      color: palette.gray[100],
      label
    }
  ];

  return (
    <Layout height="100vh" onClick={handleClickRootLayout}>
      <Layout.Body flex>
        <SideNav
          authenticatedUser={user}
          homeBaseURL="."
          signOutPath={signOutPath}
        >
          <SideNav.Item
            href={TAB_PROFILE}
            selected={currentTab === TAB_PROFILE}
            onClick={handleChangeTab}
          >
            <IconTable color={palette.white} />
          </SideNav.Item>
          <SideNav.Item
            href={TAB_PASSWORD}
            selected={currentTab === TAB_PASSWORD}
            onClick={handleChangeTab}
          >
            <IconKey color={palette.white} />
          </SideNav.Item>
          {authenticatedUser.org.salesforceID ? (
            <SideNav.Item
              href={TAB_INTEGRATIONS}
              selected={currentTab === TAB_INTEGRATIONS}
              onClick={handleChangeTab}
            >
              <IconSalesforce color={palette.white} />
            </SideNav.Item>
          ) : null}
        </SideNav>
        <Layout height="100vh" width="100%">
          <Layout.Header
            alignItems="center"
            flex
            height={theme.space_stack_xxl}
            paddingLeft={20}
          >
            <Breadcrumb items={breadcrumbItems} />
          </Layout.Header>
          <Layout.Body
            backgroundColor={palette.gray[20]}
            direction="column"
            flex
            paddingHorizontal={20}
            scrollable
          >
            {renderScreenAlert()}
            <Box paddingTop={theme.space_stack_xl} width={SCREEN_CONTENT_WIDTH}>
              {renderTab()}
            </Box>
          </Layout.Body>
        </Layout>
      </Layout.Body>
    </Layout>
  );
}

export namespace ScreenLevelAlert {
  export interface Props {
    alertKey: string;
    message: string;
    showCloseButton?: boolean;
  }
}

function ScreenLevelAlert({
  alertKey,
  message,
  showCloseButton
}: ScreenLevelAlert.Props): ReactElement {
  const variant = alertKey.startsWith('FAILURE') ? 'danger' : 'success';

  return (
    <Box marginTop="-25px" position="absolute" width={SCREEN_CONTENT_WIDTH}>
      <Alert
        boxShadow="0 0 2px 0"
        position="relative"
        showCloseButton={showCloseButton}
        title={get(copyText, `${variant}_title`)}
        variant={variant}
        zIndex={100}
      >
        {message}
      </Alert>
    </Box>
  );
}

export namespace UserProfileHeader {
  export interface Props {
    user: {
      id: string;
      firstName: string;
      lastName: string;
      username: string;
    };
  }
}

function UserProfileHeader({ user }: UserProfileHeader.Props): ReactElement {
  const fullName = `${user.firstName} ${user.lastName}`;
  const initials = `${user.firstName[0]}${user.lastName[0]}`.toUpperCase();

  return (
    <Flex alignItems="center" paddingBottom={theme.space_stack_xl}>
      <Avatar abbr={initials} size={60}>
        {fullName}
      </Avatar>
      <FlexItem paddingLeft={theme.space_inline_md}>
        <Text bold fontSize={theme.h3_fontSize} lineHeight={theme.lineHeight}>
          {fullName}
        </Text>
        <Text lineHeight={theme.lineHeight} marginBottom={theme.space_stack_sm}>
          {user.username}
        </Text>
      </FlexItem>
    </Flex>
  );
}

export default ProfileScreen;
