/* eslint-disable @typescript-eslint/no-explicit-any */

declare global {
  interface Window {
    heap?: {
      addUserProperties: (properties: { [key: string]: any }) => void;
      identify: (id: string) => void;
    };
  }
}

export function trackUser(
  id: string,
  properties: { [key: string]: any }
): void {
  const heap = window.heap;

  if (typeof heap !== 'object') return;
  if (typeof heap.identify !== 'function') return;
  if (typeof heap.addUserProperties !== 'function') return;

  heap.identify(id);
  heap.addUserProperties(properties);
}

export default trackUser;
