export default {
  connectButtonLabel: 'CONNECT',
  connectedDescription:
    'Your TargetX user account is currently connected to your Salesforce user account.',
  disconnectButtonLabel: 'DISCONNECT',
  description:
    'Connect your TargetX user account to your Salesforce user account.',
  moreInfoDescription: `Connecting your TargetX user account to your Salesforce user account enables the following integration features:\n\n* Sign into TargetX using your Salesforce credentials\n* Allow TargetX apps to make Salesforce API calls on your behalf`,
  notAvailable: 'N/A',
  heading: 'Salesforce',
  usernameHeading: 'Salesforce Username:'
};
