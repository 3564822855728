import Flex from '@targetx/mineral-ui/Flex';
import Link from '@targetx/mineral-ui/Link';
import Text from '@targetx/mineral-ui/Text';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import _apps, {
  AppEntity as _AppEntity,
  APP_ID_ADMIN,
  APP_SID_ADMIN
} from '@targetx/tx-usermgmt-api-lib/lib/constants/apps';
import {
  UserAppPermissionType,
  UserRole
} from '@targetx/tx-usermgmt-api-lib/lib/constants/enums';
import { SYSTEM_ORG_ID } from '@targetx/tx-usermgmt-api-lib/lib/constants/system';
import Image from '@targetx/tx-web-ui-lib/lib/components/Image';
import Layout from '@targetx/tx-web-ui-lib/lib/components/Layout';
import SideNav from '@targetx/tx-web-ui-lib/lib/components/SideNav';
import get from 'lodash.get';
import keyBy from 'lodash.keyby';
import React, { ReactElement, ReactNode } from 'react';
import theme from '../theme';
import copyText from './HomeScreen.copyText';

interface AppEntity extends _AppEntity {
  description: string;
  baseURL: string;
}

const apps: AppEntity[] = _apps.map(app => ({
  ...app,
  name: get(copyText, `appName_${app.sid}`),
  description: get(copyText, `appDescription_${app.sid}`),
  baseURL: ''
}));

export namespace HomeScreen {
  export interface UserPermissionEntity {
    id: string;
    targetID: string;
    type: UserAppPermissionType;
  }

  export interface UserEntity {
    id: string;
    orgID: string;
    firstName: string;
    lastName: string;
    org: {
      id: string;
      sid: string;
    };
    role: UserRole;
    username: string;
    permissions: UserPermissionEntity[];
  }

  export interface Props {
    appURLRegistry: { [key: string]: string };
    authenticatedUser: UserEntity;
    path?: string;
    signOutPath: string;
  }
}

export function HomeScreen({
  appURLRegistry,
  authenticatedUser,
  signOutPath
}: HomeScreen.Props): ReactElement {
  const tiles: ReactNode =
    authenticatedUser.orgID === SYSTEM_ORG_ID ? (
      <AppTile
        key={APP_ID_ADMIN}
        app={{
          ...(apps.find(app => app.id === APP_ID_ADMIN) as AppEntity),
          name: copyText.appName_sysadmin,
          description: copyText.appDescription_sysadmin,
          baseURL: appURLRegistry[APP_SID_ADMIN]
        }}
      />
    ) : (
      apps.map(app =>
        canAccess(authenticatedUser, app) ? (
          <AppTile
            key={app.id}
            app={{ ...app, baseURL: appURLRegistry[app.sid] }}
          />
        ) : undefined
      )
    );

  return (
    <Layout height="100vh">
      <Layout.Body backgroundColor={palette.gray[20]} flex>
        <SideNav
          authenticatedUser={authenticatedUser}
          homeBaseURL="."
          signOutPath={signOutPath}
        />
        <Layout height="100vh" width="100%">
          <Layout.Header
            alignItems="center"
            backgroundColor={palette.white}
            flex
            height={theme.space_stack_xxl}
          >
            <Text
              bold
              color={palette.gray[100]}
              fontSize={theme.fontSize_base}
              marginLeft={20}
            >
              {copyText.title}
            </Text>
          </Layout.Header>
          <Layout.Body flex grow={0} wrap>
            {tiles}
          </Layout.Body>
        </Layout>
      </Layout.Body>
    </Layout>
  );
}

function canAccess(user: HomeScreen.UserEntity, app: AppEntity): boolean {
  if (app.id === APP_ID_ADMIN) {
    return user.role === UserRole.ORG_ADMIN;
  }

  const userPermissionsKeyedByTarget = keyBy(
    user.permissions,
    ({ targetID, type }) => `${targetID}/${type}`
  );

  return Boolean(
    userPermissionsKeyedByTarget[
      `${app.id}/${UserAppPermissionType.CAN_ACCESS}`
    ]
  );
}

function AppTile({ app }: { app: AppEntity }): ReactElement {
  return (
    <Flex
      alignItems="center"
      as={Link}
      backgroundColor={palette.white}
      border="1px solid transparent"
      borderRadius={theme.borderRadius_4}
      boxShadow={theme.boxShadow_1}
      css={{
        '&:focus': { outline: 'none' },
        '&:hover': {
          border: `1px solid ${palette.blue[60]}`,
          textDecoration: 'none'
        }
      }}
      cursor="pointer"
      direction="column"
      height={220}
      href={app.baseURL}
      margin={20}
      padding={20}
      width={280}
    >
      <Text bold color={palette.gray[100]} fontSize={theme.h4_fontSize}>
        {app.name}
      </Text>
      <Text color={palette.gray[100]} marginBottom={20}>
        {app.description}
      </Text>
      <Image src={app.imageID} />
    </Flex>
  );
}

HomeScreen.copyText = copyText;

export default HomeScreen;
