export namespace RedirectCommand {
  export interface Properties {
    url: string;
  }
}

export class RedirectCommand {
  public readonly url: string;

  public constructor({ url }: RedirectCommand.Properties) {
    this.url = url;
  }
}

export default RedirectCommand;
