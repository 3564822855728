/* eslint-disable @typescript-eslint/no-explicit-any */

import { createContext } from 'react';

export namespace Dispatcher {
  export interface Payload {
    constructor: {
      name: string;
    };
    [key: string]: any;
  }
}

export interface Dispatcher {
  dispatch<R = any>(payload: Dispatcher.Payload): Promise<R> | R;
}

class DefaultDispatcher implements Dispatcher {
  public dispatch<R = any>(): R {
    return (undefined as unknown) as R;
  }
}

const dispatcher = new DefaultDispatcher();

export const DispatcherContext = createContext<Dispatcher>(dispatcher);

export default DispatcherContext;
