import UError from 'unilib-error';

const TIMEOUT = 5000;

export async function redirectOnAuthError(
  error: UError,
  authBaseURL: string
): Promise<void> {
  if (typeof error.status === 'number' && error.status === 401) {
    const signInURL = `${authBaseURL}/sign-in?redirectURL=${window.location.href}`;

    window.location.replace(signInURL);

    /*
     * NOTE:
     *   The timer below is included by design. It gives the browser time
     *   to process the window.location update and redirect. Without it,
     *   React would update state and re-render, resulting in an unwanted
     *   error message flashing on the screen for a split second before
     *   the redirects actually happens.
     *
     *   Worth noting, an alternative to this would be to throw an uncaught
     *   error, which would effectively prevent React from updating state.
     *   This works as well, but felt unclean and more hacky than this.
     */

    return new Promise(resolve => {
      const timer = setTimeout(() => {
        clearTimeout(timer);
        resolve();
      }, TIMEOUT);
    });
  }
}

export default redirectOnAuthError;
