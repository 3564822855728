export default {
  FAILURE_title: 'Operation Failed.',
  FAILURE_CLEARING_USER_SALESFORCE_MAPPING_message:
    'The system failed to disconnect your user account from Salesforce. Please try again.',
  FAILURE_LOADING_USER_message:
    'There was an error loading your user information. Please try again.',
  FAILURE_UPDATING_PASSWORD_INVALID_CREDENTIALS_message:
    'The old password you provided was incorrect. Please try again.',
  FAILURE_UPDATING_PASSWORD_UNEXPECTED_message:
    'An error occured while attempting to update your password. Please try again.',
  FAILURE_UPDATING_USER_UNEXPECTED_message:
    'An error occured while attempting to update the user. Please try again.',
  tabTitleEditBasicInfo: 'Edit Basic Info',
  tabTitleIntegrations: 'Integrations',
  tabTitleUpdatePassword: 'Update Password',
  SUCCESS_title: 'Operation Successful!',
  SUCCESS_PASSWORD_UPDATED_message:
    'You have successfully updated your password.',
  SUCCESS_USER_SALESFORCE_MAPPING_CLEARED_message:
    'You have successfully disconnected from Salesforce.',
  SUCCESS_USER_UPDATED_message: 'Your user account was updated.',
  title: 'Profile'
};
