import Box from '@targetx/mineral-ui/Box';
import Button from '@targetx/mineral-ui/Button';
import Flex from '@targetx/mineral-ui/Flex';
import { FormField } from '@targetx/mineral-ui/Form';
import Text from '@targetx/mineral-ui/Text';
import TextInput from '@targetx/mineral-ui/TextInput';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import Asterisk from '@targetx/tx-web-ui-lib/lib/components/Asterisk';
import Form from '@targetx/tx-web-ui-lib/lib/components/Form';
import noop from 'lodash.noop';
import React, {
  ChangeEvent,
  FormEvent,
  MouseEvent,
  ReactElement,
  useState
} from 'react';
import isEmpty from 'validator/lib/isEmpty';
import theme from '../theme';
import { InteractionDelegate } from '../types/Interaction';
import { PartialState } from '../types/PartialState';
import copyText from './EditProfileBasicInfoForm.copyText';

export namespace EditProfileForm {
  export interface Props {
    isProcessing?: boolean;
    user: {
      id: string;
      firstName: string;
      lastName: string;
      username: string;
    };
    onInteraction?: InteractionDelegate;
  }
}

interface State {
  firstNameInput: { value: string; hasChanged: boolean; isValid: boolean };
  lastNameInput: { value: string; hasChanged: boolean; isValid: boolean };
}

export function EditProfileForm({
  isProcessing,
  user,
  onInteraction = noop
}: EditProfileForm.Props): ReactElement {
  const initialState = {
    firstNameInput: {
      value: user.firstName,
      hasChanged: false,
      isValid: true
    },
    lastNameInput: {
      value: user.lastName,
      hasChanged: false,
      isValid: true
    }
  };

  const [state, setState] = useState<State>(initialState);

  function changeState(partialState: PartialState<State>): void {
    setState(currentState => ({ ...currentState, ...partialState }));
  }

  const { firstNameInput, lastNameInput } = state;

  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    const { name, value } = event.target;

    let isValid = false;

    const hasChanged =
      initialState[`${name}Input` as keyof State].value !== value;

    switch (name) {
      case 'firstName':
      case 'lastName':
        isValid = !isEmpty(value, { ignore_whitespace: true });
        break;
      default:
        break;
    }

    changeState({ [`${name}Input`]: { value, isValid, hasChanged } });
  }

  function handleReset(event: MouseEvent<HTMLButtonElement>): void {
    event.preventDefault();

    changeState(initialState);
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    onInteraction({
      type: EditProfileForm.INTERACTION_SUBMIT_BUTTON_CLICKED,
      userID: user.id,
      ...(firstNameInput.hasChanged
        ? { firstName: firstNameInput.value.trim() }
        : {}),
      ...(lastNameInput.hasChanged
        ? { lastName: lastNameInput.value.trim() }
        : {})
    });
  };

  const canSubmit =
    Object.values(state).every((input): boolean => input.isValid) &&
    Object.values(state).some((input): boolean => input.hasChanged);

  return (
    <Form onSubmit={handleSubmit}>
      <Box
        backgroundColor={palette.white}
        border={`1px solid ${palette.gray[50]}`}
        borderRadius={theme.borderRadius_2}
        padding={theme.space_inset_lg}
      >
        <FormField
          name="firstName"
          input={TextInput}
          label={
            <Text
              color={firstNameInput.isValid ? undefined : theme.color_danger}
            >
              {copyText.firstNameInputLabel}
              <Asterisk color={theme.color_danger} />
            </Text>
          }
          readOnly={isProcessing}
          value={firstNameInput.value}
          variant={firstNameInput.isValid ? undefined : 'danger'}
          onChange={handleChange}
        />
        <FormField
          name="lastName"
          input={TextInput}
          label={
            <Text
              color={lastNameInput.isValid ? undefined : theme.color_danger}
            >
              {copyText.lastNameInputLabel}
              <Asterisk color={theme.color_danger} />
            </Text>
          }
          marginTop={theme.space_stack_md}
          readOnly={isProcessing}
          value={lastNameInput.value}
          variant={lastNameInput.isValid ? undefined : 'danger'}
          onChange={handleChange}
        />
      </Box>
      <Flex justifyContent="end" paddingVertical={theme.space_stack_lg}>
        <Button
          marginRight={20}
          minimal
          size="medium"
          type="reset"
          width="100px"
          onClick={handleReset}
        >
          {copyText.resetButtonLabel}
        </Button>
        <Button
          disabled={!canSubmit || isProcessing}
          primary
          size="medium"
          type="submit"
          width="100px"
        >
          {copyText.submitButtonLabel}
        </Button>
      </Flex>
    </Form>
  );
}

EditProfileForm.INTERACTION_SUBMIT_BUTTON_CLICKED = `${EditProfileForm.name}.INTERACTION_SUBMIT_BUTTON_CLICKED`;

export default EditProfileForm;
